.data-filter {
  li {
    margin-right: 6.25px;
  }
  select, input {
    min-width: 155px;
  }
  .select select {
    padding-top: 11px;
    padding-bottom: 11px;
  }
  .btn {
    height: 41px;
    padding-left: 2em;
    padding-right: 2em;
    &.btn--square {
      width: 41px;
      padding: 0;
      font-size: 1.5em;
    }
  }
}
