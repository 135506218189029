.tbl__fixed__wrapper {
  margin: 12px 0 0;
  padding: 35px 20px 0;
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  position: relative;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: auto;
}

.tbl__fixed__portal {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  verflow-x: hidden;
  overflow-y: scroll;
}

.tbl {
  margin: 20px 0;
  border-collapse: separate;
  border-spacing: 0;
  display: table;
}

.tbl.o__fixed {
  margin: 0;
}
.tbl.o__fit {
  min-width: 100%;
}

.tbl__cell.o__fixed-header {
  position: initial;
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 0!important;
  border-top: 0!important;
}

.tbl__cell:first-child {
  border-left: 1px solid #e9eef2;
}

.tbl__row:first-child .tbl__cell, tr:first-child .tbl__cell {
  border-top: 1px solid #e9eef2;
}

.tbl__body .tbl__cell {
  border-bottom: 1px solid #e9eef2;
}

.tbl__cell, td.tbl__cell {
  background-color: #fff;
}

.tbl__fixed-header__text {
  position: absolute;
  top: 0;
}

.tbl__cell {
  white-space: nowrap;
  border-right: 1px solid #e9eef2;
  padding: 9px 15px;
  text-align: left;
  display: table-cell;
  vertical-align: middle;
}

.btn.o__cell-secondary {
  padding: 10px 18px;
  border-width: 0;
  text-align: center;
  border-radius: 0;
  width: 100%;
}

.tbl__cell.o__contains-button {
  padding: 0;
}

.shim__force-cell-button-width {
  width: 52px;
}