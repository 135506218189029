section.tutor-profile {
  .panel.tutor-profile-header {
    border-top: none;
  }
}

.foreground {
  z-index: 1000;
}

.star-rating-icons {
  padding-right: 3px;
}

.tutor-summary-data {
  .fa {
    margin-right: 3px;
    width: 16px;
  }
}

.youtube-lite {
  background-color: #1b1b1b;
}

.youtube-lite.stretch-preview {
  cursor: pointer;
  background-size: cover !important;
  background-position: center !important;
  text-align: center;
  border-radius: 4px;
  i {
    font-size: 21px;
    color: #fff;
    cursor: pointer;
    padding: 12px 25px 12px 29px;
    background-color: #000;
    opacity: .8;
    position: absolute;
    top: 44%;
    left: 44%;
    border-radius: 6px;
    &:hover {
      background-color: #ed4c46;
      opacity: 1;
    }
  }
}

.tutor-profiel-mini-section {
  border-bottom: 1px solid #e8ebe9;
}

.review-thumb {
  height: 45px;
  width: 45px;
}

.tutor-ratings-section {
  min-height: 30em;
  .tutor-review {
    padding-bottom: 1em;
    margin-bottom: 1.5em;
    border-bottom: 1px solid #e8ebe9;
    .name {
      font-size: 1.2em;
      margin-left: 0.6em;
    }
  }
}

