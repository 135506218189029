.dashboard-avatar .avatar {
  width: 120px;
  height: 120px;
}

.dashboard-name {
  font-size: 1.3em;
  font-weight: 500;
}

.btn.dashboard-plan {
  min-width: 7em;
}

.dashboard-title {
  color: #6b757b;
  font-size: 20px;
  font-size: 1.538em;
}

.holiday-list-item {
  display: block;
  padding: 7.5px 0px;
  clear: both;
  line-height: 1.1em;
}

.dashboard-lesson-counts {
  p {
    margin-bottom: 0px;
    margin: 0 0 9px;
    line-height: 1.1em;
  }
  p.count {
    font-size: 1.4em;
    font-weight: 600;
  }
}

.list-tutor-avatar {
  display: table-cell;
  float: none;
}