.phonetic-square {
  height: 3em;
  width: 3em;
  cursor: pointer;
  position: relative;
  float: left;
  margin-right: 1em;
}

.ipa {
  line-height: 1.5em;
  font-size: 2em;
  color: #fff;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.phonetic-list {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.phonetic-list li {
  display: inline-block;
}

.pronunciation-strong {
  background-color: #57bb89;
}

.pronunciation-average {
  background-color: #f9d04b;
}

.pronunciation-weak {
  background-color: #ee4d4e;
}

.pronunciation-no-rating {
  background-color: #99a2a2;
}

@media (max-width: 767px) {
  .phonetic-square {
    height: 2.5em;
    width: 2.5em;
    margin-right: 0.75em;
  }

  .ipa {
    line-height: 1.25em;
    font-size: 2em;
  }
}