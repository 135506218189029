@media (min-width: 768px) {
  .avatar-name {
    min-width: 120px;
  }
  .list-cancel-button {
    min-width: 110px;
  }
}

.slider-item h3 {
  font-size: 36px;
  margin: 10px;
  padding: 2%;
  position: relative;
  background: #00558B;
  color: #fff;
  line-height: 100px;
}

#app-wrapper {
  .DateInput__display-text--focused {
    background: #00a699;
    border-color: #00a699;
    border-radius: 2px;
    color: #fff;
  }
}

.data-filter {
  .DateInput {
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    color: #757575;
    margin: 0;
    padding: 5px;
    background: #fff;
    position: relative;
    display: inline-block;
    width: 130px;
    vertical-align: middle;
  }
  .DateInput__display-text--focused {
    background: #00a699;
    border-color: #00a699;
    border-radius: 2px;
    color: #fff;
  }

  .DayPicker__week-header {
    li {
      margin-right: 0;
    }
  }
}
