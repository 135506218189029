.faq-section {
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 20px;
  padding-bottom: 1.538em;
}

h2.section-title {
  font-weight: 300;
}

.accordion-header {
  position: relative;
  padding-left: 36px;
  padding-left: 2.769em;
}

.accordion-content {
  transition: all .2s ease-in;
  overflow: hidden;
  height: 0;
  p {
    transition: all .2s ease-in;
    margin: 0 0 0 36px;
    margin: 0 0 0 2.769em;
    padding-top: 20px;
    padding-top: 1.538em;
  }
  ul {
    padding-top: 1em;
    margin: 0 0 0 36px;
    margin: 0 0 0 2.769em;
  }
}

.accordion-content.active {
  transition: all .2s ease-in;
  height: auto;
}

h2.accordion-header {
  font-size: 20px;
  font-size: 1.538em;
  border-top: 1px solid #e2e2e2;
  cursor: pointer;
  margin: 20px 0 0;
  margin: 1.538em 0 0;
  outline: none;
  padding-top: 20px;
  padding-top: 1.538em;
  font-weight: 300;
  &:before {
    position: absolute;
    left: 0;
    top: 50%;
    content: '+';
    border-radius: 100%;
    display: inline-block;
    width: 16px;
    width: 1.231em;
    line-height: 16px;
    line-height: 1.231em;
    text-align: center;
    margin-right: 20px;
    margin-right: 1.538em;
    transition: all .50s ease-in-out;
  }
}

h2.accordion-header.active {
  &:before {
    content: '–';
    padding-bottom: 1px;
    padding-bottom: 0.077em;
    line-height: 16px;
    line-height: 1.231em;
    transition: all .50s ease-in-out;
  }
}
