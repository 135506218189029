.tooltip {
  position: absolute;
  z-index: 3000;
  max-width: 280px;
  opacity: 1;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0,0,0,0.1);
  background-color: #fff;
  top: 0;
  left: 0;
  border-color: #fff;
}

.tooltip-top-right:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: -10px;
  right: 14px;
  bottom: auto;
  border: 10px solid transparent;
  border-top: 0;
  border-bottom-color: rgba(0,0,0,0.1);
}

.tooltip-top-right:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: -9px;
  right: 15px;
  bottom: auto;
  border: 9px solid transparent;
  border-top: 0;
  border-bottom-color: #fff;
}

.tooltip-bottom-middle:before {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
  top: auto;
  border: 10px solid transparent;
  border-bottom: 0;
  border-top-color: rgba(0,0,0,0.1);
}


.tooltip-bottom-middle:after {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: -9px;
  left: 50%;
  margin-left: -9px;
  top: auto;
  border: 9px solid transparent;
  border-bottom: 0;
  border-top-color: #fff;
}
