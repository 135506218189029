.lb-header {
  height: 60px;
  background: #fff;
  margin: 0;
  border-bottom: 1px solid #e8ebe9;
  #logo {
    background: url("#{$images-path}/logo.png") no-repeat center scroll;
    width: 120px;
    height: 21px;
    vertical-align: middle;
    text-indent: -9999px;
    display: block;
    text-decoration: none;
    background-size: 120px 21px;
  }
  .nav-items {
    height: 60px;
  }

  .nav-item {
    align-self: center;
    padding: 24px 10px;
    &:first-child {
      padding-left: 0;
    }
    a {
      color: #79776b;
      &:hover, &:active {
        color: #f15a22;
        background-color: transparent;
      } 
    }
  }

  .fa-caret-down {
    position: relative;
    top: 1px;
    margin-left: 3px;
    margin-right: 3px;
  }

  .dropdown-menu {
    padding: 7.5px 0;
    min-width: 180px;
    max-width: 300px;
    .menu-item {
      display: block;
      padding: 7.5px 15px;
      clear: both;
      line-height: 18px;
    }
  }

  .dropdown-trigger {
    .dropdown-menu {
      display: block;
      top: 60px;
      right: 0;
      left: auto;
    }
    .dropdown-menu>li {
      white-space: nowrap;
    }
  }
  .icon-gray {
    opacity: .5;
  }
}


//.navbar {
//  margin-bottom: 0px;
//}
//
//.lb-header {
//  a.link-reset {
//    color: inherit;
//    &:hover, &:active, &:visited {
//      color: $navbar-default-link-color;
//    }
//  }
//  .fa-bars {
//    color: $navbar-default-link-color;
//    font-size: 21px;
//    font-size: 1.615em;
//  }
//  .navbar-header, .navbar {
//    height: 60px;
//  }
//  .header--sm {
//    position: relative;
//    border-bottom: 1px solid #e8ebe9;
//    border-right: none;
//    border-left: none;
//    border-top: none;
//    border-radius: 0;
//    background-color: #ffffff;
//    min-height: 45px;
//  }
//
//  .logo--sm {
//    #logo {
//      margin: 0;
//      margin-left: 15px;
//      background-size: 120px 21px;
//      min-height: 45px;
//    }
//  }
//
//  .menu--sm {
//    position: absolute;
//    right: 15px;
//    top: 13px;
//    a {
//      color: $base-color;
//
//      &:hover, &:active {
//        color: $link-hover-color
//      }
//    }
//  }
//
//  #logo {
//    background: url("../../../public/images/logo.png") no-repeat center scroll;
//    width: 120px;
//    height: 21px;
//    vertical-align: middle;
//    text-indent: -9999px;
//    display: block;
//    text-decoration: none;
//    background-size: 120px 21px;
//  }
//  .nav>li {
//    line-height: 60px;
//    height: 60px;
//  }
//  .user-item {
//    position: relative;
//  }
//  .user-name {
//    margin-left: 20px;
//  }
//  .user-avatar {
//    background: url("../../../public/images/default_avatar_orange.png") 0 0 no-repeat;
//    background-size: cover;
//    height: 32px;
//    width: 32px;
//    position: absolute;
//    top: 13px;
//    left: 0;
//    img {
//      vertical-align: top;
//      height: 28px;
//      width: 28px;
//    }
//  }
//  .fa-caret-down {
//    position: relative;
//    top: 1px;
//    margin-left: 3px;
//    margin-right: 3px;
//  }
//  .fa-bell, .fa-envelope {
//    top: 3px;
//  }
//  .dropdown-trigger {
//    .dropdown-menu {
//      display: block;
//      top: 60px;
//      right: 0;
//      left: auto;
//    }
//    .dropdown-menu>li {
//      white-space: nowrap;
//    }
//  }
//  .nav--sm {
//    position: fixed;
//    top: 0;
//    bottom: 0;
//    left: 0;
//    right: 0;
//    z-index: 2001;
//    visibility: hidden;
//  }
//  .nav--sm.show {
//    visibility: visible;
//  }
//
//  .nav-mask--sm {
//    opacity: .6;
//    position: absolute;
//    cursor: pointer;
//    height: 100%;
//    width: 100%;
//    background-color: #000;
//  }
//
//  .nav-content--sm {
//    right: -285px;
//    position: absolute;
//    width: 285px;
//    height: 100%;
//    border: none;
//    background: #fff;
//    -webkit-transition: right 0.2s cubic-bezier(0.4, 0, 0.2, 1);
//    -moz-transition: right 0.2s cubic-bezier(0.4, 0, 0.2, 1);
//    -o-transition: right 0.2s cubic-bezier(0.4, 0, 0.2, 1);
//    transition: right 0.2s cubic-bezier(0.4, 0, 0.2, 1);
//    -webkit-box-shadow: 3px 3px 10px rgba(0,0,0,0.1);
//    -moz-box-shadow: 3px 3px 10px rgba(0,0,0,0.1);
//    -ms-box-shadow: 3px 3px 10px rgba(0,0,0,0.1);
//    box-shadow: 3px 3px 10px rgba(0,0,0,0.1);
//    z-index: 9999;
//    .nav-header {
//      height: 45px;
//      background: #ecf0f1;
//      .panel-close {
//        margin-top: 12px;
//        margin-right: 15px;
//        font-size: 1.6em
//      }
//    }
//    .nav-menu {
//      font-size: 20px;
//      font-size: 1.538em;
//      padding-left: 25px;
//      padding-right: 25px;
//      .menu-group {
//        margin-bottom: 34px;
//        line-height: 46px;
//      }
//      a {
//        color: #79776b;
//      }
//    }
//  }
//
//  .nav-item > a {
//    line-height: 60px;
//    padding-top: 0px;
//    padding-bottom: 0px;
//    &:hover {
//      transition: color 0.5s;
//    }
//  }
//
//  .show .nav-content--sm {
//    right: 0;
//  }
//}
//
//.navbar-toggle {
//  color: $link-color;
//  border: none;
//  &:hover {
//    border: none;
//  }
//}
//
//.dropdown-menu {
//  padding: 7.5px 0;
//  min-width: 180px;
//  max-width: 300px;
//  .menu-item {
//    display: block;
//    padding: 7.5px 15px;
//    clear: both;
//    line-height: 18px;
//  }
//}
//
//.ticket-menu {
//  .panel-body {
//    padding: 0 0 2px 0;
//  }
//  .ticket-count {
//    padding: 5px 15px 5px;
//  }
//}
//
//.timezone-menu {
//  .panel-body {
//    padding: 0 0 2px 0;
//  }
//  .timezone {
//    padding: 5px 15px 5px;
//  }
//  .timezone-title {
//    margin-bottom: 5px;
//  }
//  .change-timezone {
//    padding: 10px 15px 0 15px;
//  }
//}
//
//.navbar-nav.nav-user-menu {
//  padding-left: 15px;
//}
//
//.navbar-default {
//  border-bottom: $border-base; 
//  border-right: none;
//  border-left: none;
//  border-top: none;
//  border-radius: 0;
//  .icon-gray {
//    color: #79776b;
//    opacity: .5;
//  }
//  .icon-large {
//    font-size: 22px;
//    font-size: 1.692em;
//  }
//  .navbar-toggle {
//    padding: 0;
//    color: $link-color;
//    border: none;
//    &:hover, &:focus {
//      border: none;
//    }
//  }
//  .navbar-toggle.toggle-custom {
//    margin-top: 17px;
//    &:hover, &:focus, &:active {
//      background-color: transparent;
//    }
//  }
//  .navbar-nav > li > a {
//    line-height: 60px;
//    padding-top: 0px;
//    padding-bottom: 0px;
//    &:hover {
//      transition: color 0.5s;
//    }
//  }
//  .navbar-nav > li.free-trial > a {
//    color: #fff;
//    background: $btn-success-bg;
//    border: 1px solid $btn-success-border;
//    border-radius: $border-radius-base;
//    line-height: 34px;
//    height: 35px;
//    margin-top: 13px;
//    &:hover, &:active {
//      transition: background-color 0.3s;
//      background-color: $btn-success-hover-bg;
//      border-bottom: 1px solid $btn-success-hover-border;
//    }
//  }
//  
//  .navbar-nav > li > a {
//    line-height: 60px;
//    padding-top: 0px;
//    padding-bottom: 0px;
//    &:hover {
//      transition: color 0.5s;
//    }
//  }
//
//  .navbar-nav > li.vertical-align {
//    line-height: 60px;
//    padding-top: 0px;
//    padding-bottom: 0px;
//  }
//
//  a:hover {
//    transition: color 0.5s;
//  }
//  .navbar-form {
//    border-bottom: $border-base;
//  }
//}
//
//@media (min-width: 768px) {
//  .navbar-nav {
//    float: left;
//    margin: 0;
//  }
//}
//
//
//@media (max-width: 767px) {
//  .navbar-default .navbar-nav>li>a {
//    color: #4B6274;
//    background: #fff;
//    transition: background 0.3s;
//    padding: 0px 25px;
//    font-size: 16px;
//    font-size: 1.231em;
//    line-height: 44px;
//  }
//  .navbar-default .navbar-nav > li.free-trial > a {
//    margin: 0px 12px;
//    padding-left: 13px;
//    margin-bottom: 20px;
//  }
//  .navbar-default .navbar-nav > li.login > a {
//    margin-bottom: 10px;
//  }
//  .navbar-default .navbar-nav {
//    margin-top: 0px;
//    margin-bottom: 0px;
//  }
//  .navbar-collapse {
//    background-color: white;
//    position: absolute;
//    z-index: 9999;
//    width: 100%;
//  }
//}
//