.tabs-header {
  padding: 0 20px;
}


.tabs {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  display: table;
  table-layout: fixed;
  width: 100%;
}

.tabs>li {
  display: inline-block;
  vertical-align: middle;
}

.tab-item {
  display: block;
  padding: 1em 2em;
  position: relative;
  text-overflow: ellipsis;
  text-align: center;
  color: #82888a;
  min-width: 150px;
  cursor: pointer;
  &:hover {
    color: #565a5c;
  }
}

@media (max-width: 767px) {
  .tab-item {
    padding: 0.8em 1.1em;
    min-width: 0;
  }
}

.tab-item[aria-selected="true"], .tab-item[aria-selected="true"]:hover, .tab-item[aria-selected="true"]:focus {
  color: #565a5c;
  cursor: default;
  background-color: #f7f7f7;
  border-bottom: 3px solid #fed55a;
}

.tab-item::before {
  background-color: #aaa;
  bottom: -1px;
  content: '';
  height: 0px;
  left: 0;
  position: absolute;
  right: 0;
  transition: 0.2s ease height;
  z-index: 1;
}