input[type="text"],
input[type="tel"],
input[type="number"],
input[type="email"],
input[type="password"],
textarea,
select {
  border: 1px solid #b9b9af;
  padding: 11px 11px;
  background-color: #fff;
  -moz-border-radius: $border-radius-base;
  -webkit-border-radius: $border-radius-base;
  -khtml-border-radius: $border-radius-base;
  border-radius: $border-radius-base;
  outline: none;
  &:focus, &:hover, &:visited, &:active, &:checked, &:not(:checked){
    outline: none;
    box-shadow: none;
  }
}

input[type="text"],
input[type="tel"],
input[type="number"],
input[type="email"],
input[type="password"],
textarea {
  width: 100%;
}

.select {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
}

.select select {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 2em;
}

.select:before {
  content: '\25bc';
  position: absolute;
  pointer-events: none;
  color: #82888a;
  top: 0;
  bottom: 1px;
  padding-top: 0.7em;
  line-height: 1;
  right: 0;
  width: 2em;
  text-align: center;
  transform: scale(0.84, 0.42);
  -webkit-transform: scale(0.84, 0.42);
  -moz-transform: scale(0.84, 0.42);
  -ms-transform: scale(0.84, 0.42);
  -o-transform: scale(0.84, 0.42);
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.84, M12=0, M21=0, M22=0.42, SizingMethod='auto expand')";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.84, M12=0, M21=0, M22=0.42, SizingMethod='auto expand');
}



select {
  -webkit-appearance: none;
  background: white\0 !important;
}


.invalid[type="text"],
.invalid[type="password"],
.invalid[type="search"],
.invalid[type="email"],
.invalid[type="url"],
.invalid[type="number"],
.invalid[type="tel"],
textarea.invalid,
select.invalid,
input.invalid[type="radio"],
input.invalid[type="checkbox"],
[type="text"]:invalid, [type="password"]:invalid,
[type="search"]:invalid, [type="email"]:invalid,
[type="url"]:invalid, [type="number"]:invalid,
[type="tel"]:invalid,
textarea:invalid,
select:invalid,
input[type="radio"]:invalid,
input[type="checkbox"]:invalid {
  border-color: #ffb400;
  background-color: #fff8e5;
  &:focus {
    border-color: #ffb400;
  }
}

label {
  display: block;
  padding-top: 11px;
  padding-bottom: 10px;
}

label.invalid {
  padding-top: 6px;
  padding-bottom: 6px;
}
