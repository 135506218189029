.subnav {
  height: 40px;
  background-color: #2a4258;
  position: relative;
  margin: 0;
}

//.subnav[data-sticky="true"].pinned,.subnav[data-transition-at] {
//  position: fixed;
//  top: 0;
//  left: 0;
//  right: 0;
//  z-index: 1000;
//}
//
//.subnav {
//  position: relative;
//  background-color: #2a4258;
//  -webkit-user-select: none;
//  -moz-user-select: none;
//  -ms-user-select: none;
//  user-select: none;
//}
//
//.subnav:before,.subnav:after {
//  content: " ";
//  display: table;
//}
//
//.subnav:after {
//  clear: both;
//}
//
//.subnav[data-transition-at] {
//  -webkit-backface-visibility: hidden;
//  opacity: 0;
//  filter: alpha(opacity=0);
//  -webkit-transition: opacity 0.2s;
//  transition: opacity 0.2s;
//}
//
//.subnav[data-transition-at][aria-hidden="false"] {
//  opacity: 1;
//  filter: alpha(opacity=100);
//}
//
//.subnav[data-transition-at][aria-hidden="true"] {
//  z-index: -1;
//}
//
//.subnav-placeholder {
//  display: none;
//}
//
//.subnav-placeholder.placeholding {
//  display: block;
//}
//
//.subnav-list {
//  margin-left: -14px;
//  margin-right: -14px;
//}
//
//.subnav-text,.subnav-item {
//  position: relative;
//  float: left;
//  padding: 10px 14px;
//  color: #fff;
//}
//
//.subnav-item {
//  color: #cacccd;
//}
//
//.subnav-item.icon,.subnav-item .icon:first-child {
//  color: #cacccd;
//}
//
//.subnav-item.icon {
//  padding-top: 12px;
//}
//
//.subnav-item .icon:first-child {
//  margin-right: 5px;
//  position: relative;
//}
//
//.subnav-item:hover,.subnav-item:focus,.subnav-item[aria-selected="true"] {
//  text-decoration: none;
//  color: #fff;
//}
//
//.subnav-item:hover.icon,.subnav-item:hover .icon:first-child,.subnav-item:focus.icon,.subnav-item:focus .icon:first-child,.subnav-item[aria-selected="true"].icon,.subnav-item[aria-selected="true"] .icon:first-child {
//  text-shadow: inherit;
//  color: #fff;
//}
//
//.subnav-item[aria-selected="true"]:before {
//  position: absolute;
//  content: '';
//  bottom: 0;
//  left: 14px;
//  right: 14px;
//  height: 4px;
//  background: #cacccd;
//}//