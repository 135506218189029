.flex-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

ul.flex-container > li {
  margin-right: 6.25px;
}

.flex-row {
  flex-direction: row;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-items-start {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flex-items-end {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.flex-ext {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1
}