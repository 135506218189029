.plan-wrapper.loading {
  height: 250px;
}

@media (min-width: 768px) {
  .price-plan {
    margin-bottom: 0;
  }
  .price-block-spotlight {
    margin-top: -28px;
  }
}
.price-plan {
  text-align: center;
  margin-bottom: 1em;
}

@media (min-width: 1200px) {
  .price-plan-wrap {
    margin: 0 auto;
    max-width: 350px;
  }
}

.price-plan-wrap {
  border: 1px solid #e8ebe9;
  border-radius: 2px;
  padding: 0 0 20px;
  background-color: #fff;
}

.price-block-spotlight .price-plan-header {
  border: none;
  margin: 0;
  height: 83px;
}
.price-plan-header {
  position: relative;
  display: table;
  width: 100%;
  height: 6em;
  h4 {
    display: table-cell;
    font-size: 27px;
    font-size: 2.077em;
    font-weight: 600;
  }
}

.price-block-spotlight .price-plan-price {
  margin: -5px 0 0;
}

.price-plan-price {
  font-weight: 400;
  line-height: 3.5em;
  sup {
    font-size: 24px;
    font-size: 1.846em;
    position: relative;
    top: -18px;
    font-weight: 600;
  }
  .price {
    font-size: 50px;
    font-size: 3.846em;
  }
  sub {
    font-weight: 600;
    font-size: 16px;
    font-size: 1.231em;
    position: relative;
    top: 0;
  }
}

.price-plan-num, .price-per-class {
  position: relative;
  margin-bottom: 1em;
  line-height: 1.4rem;
  font-size: 1.1em
}

.timeline-container {
  margin-top: 100px;
  margin-bottom: 50px;
  position: relative;
  padding: 0 7.5%;
}

.timeline-explanation-container {
  position: relative;
  padding: 0 7.5%;
  text-align: left;
}

.timeline-point {
  position: absolute;
  left: 1em;
  top: 0;
}
.timeline-point-tooltip {
  position: relative;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  padding: 6px 10px;
  left: -50%;
  top: -60px;
}

.timeline-point-marker {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: -6px;
  background: #ffffff;
}

.timeline-point-label {
  position: absolute;
  left: -40%;
  top: 34px;
  text-align: center;
}

.timeline-segment-trial {
  background-color: #57bb89;
}

.timeline-segment-first-cycle {
  background-color: #8a74b9;
}

.timeline-segment-second-cycle {
  background-color: #3499e0;
}

.timeline-segment-third-cycle {
  background-color: #efb57c;
}

.timeline-segment {
  text-align: center;
  height: 24px;
}



