.subnav {
  position: relative;
  background-color: #505555;
  border-bottom: $border-base;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.subnav:before, .subnav:after {
  content: " ";
  display: table;
}

.subnav-list {
  margin-bottom: 0;
  margin-left: -14px;
  margin-right: -14px;
}

.subnav-item {
  color: #cacccd;
  position: relative;
  float: left;
  padding: 10px 14px;
  &:hover, &:active {
    color: #fff;
  }
}

a.subnav-item {
  color: #cacccd;
  text-decoration: none;
  &:hover, &:active {
    color: #fff;
  }
}

.subnav-item[aria-selected="true"]:before {
  position: absolute;
  content: '';
  bottom: 0;
  left: 14px;
  right: 14px;
  height: 4px;
  background: #cacccd;
}

.sidenav-list {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.sidenav-item {
  display: block;
  padding: 6px 0;
  font-size: 14px;
  font-size: 1.0769em;
  color: #82888a;
}



