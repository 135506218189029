.pagination {
  height: 20px;
  margin: 18px 0;
}

.pagination ul {
  display: inline-block;
  margin-left: 0;
  margin-bottom: 0;
}

.pagination li {
  display: inline;
}

.pagination a, .pagination span {
  float: left;
  padding: 5px 10px;
  margin-left: 10px;
  text-decoration: none;
  border: 1px solid #c4c4c4;
  border-radius: 1px;
  background-color: #fff;
}

.pagination .gap span, .pagination .gap a, .pagination .gap a:hover {
  padding: 0 5px;
  border: 0;
  background: transparent;
  box-shadow: 0 0 0 #000;
}

.pagination .active a {
  color: #565a5c;
  background-color: #edefed;
  cursor: default;
}

.pagination a:hover {
  border-color:#aaa
}

.pagination li:first-child a {
  margin-left: 0;
}

.results-count {
  margin: 0px 0px 5px 2px;
}