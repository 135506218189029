// For overriding bootstrap

$images-path: '../assets/images';
$base-color: #565a5c;
$grid-gutter-width: 25px;
$font-size-base: 13px;
$navbar-height: 60px;
$navbar-default-bg: #ffffff;
$navbar-default-link-color: #79776b;
$navbar-default-link-hover-color: #f15a22;
$navbar-default-link-active-bg: #f15a22;
$link-color: #f27242;
$link-hover-color: #f15a22;
$radius: 2px;
$panel-border-radius: 0px;
$border-radius-base: 2px;
$border-radius-zero: 0px;
$brand-primary: #f27242;
$border-base: 1px solid #e8ebe9; 
$btn-primary-color: #fff;
$btn-primary-border: #f27242; // orange
$btn-primary-bg: #f27242;
$btn-primary-hover-border: #f15a22;
$btn-primary-hover-bg: #f15a22;
$btn-success-color: #fff;
$btn-success-border: #57bb89; // green
$btn-success-bg: #57bb89; 
$btn-success-hover-border: #108d4c;
$btn-success-hover-bg: #108d4c;
$btn-info-color: #fff;
$btn-info-border: #589CEB; // blue
$btn-info-bg: #589CEB;
$btn-info-hover-border: #398fd1;
$btn-info-hover-bg: #398fd1;
$yellow: #fed55a;
$green: #57bb89;
$light-gray: #cacccd;
$light-red: #fceeee;
$light-yellow: #fae86f;
$light-blue: #f4f7f8;
$screen-lg-min: 1100px;
$screen-md-min: 768px;

// $textColor: #4B5C66;
// $navbarText: #4B5C66;
// $dropdownLinkBackgroundHover: #FF702A;
// $linkColor: #589CEB;
// $linkColorHover: #4B5C66;
// $gridColumnWidth: 60px;
// $gridGutterWidth: 20px;

@import "vendor/font-awesome/scss/font-awesome";
@import "vendor/bootstrap-sass/bootstrap";
@import "mixins/margin";
@import "mixins/grid";
@import "base";
@import "appear";
@import "alert";
@import "tab";
@import "materials";
@import "header";
@import "footer";
@import "secondary_nav";
@import "form";
@import "button";
@import "panel";
@import "modal";
@import "icon";
@import "list";
@import "tooltip";
@import "auth";
@import "faq";
@import "subnav";
@import "sidenav";
@import "calendar";
@import "dashboard";
@import "student-tutor-list";
@import "student-tutor-profile";
@import "pricing";
@import "loading";
@import "pagination";
@import "star-ratings";
@import "pronunciation";
@import "flex-base";
@import "task-list";
@import "data-filter";
@import "react-dates";
@import "shame";


@include make-space;
@include make-condensed-grid-columns;
@include make-grid-columns;