.show {
  display: block !important;
}

tr.show {
  display: table-row !important;
}

th.show,td.show {
  display: table-cell !important;
}

.hide {
  display: none !important;
}

tr.hide {
  display: none !important;
}

th.hide,td.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.show-print {
  display: none !important;
}

tr.show-print {
  display: none !important;
}

th.show-print,td.show-print {
  display: none !important;
}

@media print {
  .show-print {
    display: block !important;
  }

  tr.show-print {
    display: table-row !important;
  }

  th.show-print,td.show-print {
    display: table-cell !important;
  }

  .hide-print {
    display: none !important;
  }

  tr.hide-print {
    display: none !important;
  }

  th.hide-print,td.hide-print {
    display: none !important;
  };
}

.show-phone {
  display: none !important;
}

tr.show-phone {
  display: none !important;
}

th.show-phone,td.show-phone {
  display: none !important;
}

.show-tablet {
  display: none !important;
}

tr.show-tablet {
  display: none !important;
}

th.show-tablet,td.show-tablet {
  display: none !important;
}

.show-desktop {
  display: none !important;
}

tr.show-desktop {
  display: none !important;
}

th.show-desktop,td.show-desktop {
  display: none !important;
}

@media (max-width: 744px) {
  .show-phone {
    display: block !important;
  }

  tr.show-phone {
    display: table-row !important;
  }

  th.show-phone,td.show-phone {
    display: table-cell !important;
  }

  .hide-phone {
    display: none !important;
  }

  tr.hide-phone {
    display: none !important;
  }

  th.hide-phone,td.hide-phone {
    display: none !important;
  };
}

@media (min-width: 745px) and (max-width: 1044px) {
  .show-tablet {
    display: block !important;
  }

  tr.show-tablet {
    display: table-row !important;
  }

  th.show-tablet,td.show-tablet {
    display: table-cell !important;
  }

  .hide-tablet {
    display: none !important;
  }

  tr.hide-tablet {
    display: none !important;
  }

  th.hide-tablet,td.hide-tablet {
    display: none !important;
  };
}

@media (min-width: 1045px) {
  .show-desktop {
    display: block !important;
  }

  tr.show-desktop {
    display: table-row !important;
  }

  th.show-desktop,td.show-desktop {
    display: table-cell !important;
  }

  .hide-desktop {
    display: none !important;
  }

  tr.hide-desktop {
    display: none !important;
  }

  th.hide-desktop,td.hide-desktop {
    display: none !important;
  };
}

.show-sm {
  display: none !important;
}

tr.show-sm {
  display: none !important;
}

th.show-sm,td.show-sm {
  display: none !important;
}

.show-md {
  display: none !important;
}

tr.show-md {
  display: none !important;
}

th.show-md,td.show-md {
  display: none !important;
}

.show-lg {
  display: none !important;
}

tr.show-lg {
  display: none !important;
}

th.show-lg,td.show-lg {
  display: none !important;
}

@media (max-width: 767px) {
  .show-sm {
    display: block !important;
  }

  tr.show-sm {
    display: table-row !important;
  }

  th.show-sm,td.show-sm {
    display: table-cell !important;
  }

  .hide-sm {
    display: none !important;
  }

  tr.hide-sm {
    display: none !important;
  }

  th.hide-sm,td.hide-sm {
    display: none !important;
  };
}

@media (min-width: 768px) and (max-width: 1099px) {
  .show-md {
    display: block !important;
  }

  tr.show-md {
    display: table-row !important;
  }

  th.show-md,td.show-md {
    display: table-cell !important;
  }

  .hide-md {
    display: none !important;
  }

  tr.hide-md {
    display: none !important;
  }

  th.hide-md,td.hide-md {
    display: none !important;
  };
}

@media (min-width: 1100px) {
  .show-lg {
    display: block !important;
  }

  tr.show-lg {
    display: table-row !important;
  }

  th.show-lg,td.show-lg {
    display: table-cell !important;
  }

  .hide-lg {
    display: none !important;
  }

  tr.hide-lg {
    display: none !important;
  }

  th.hide-lg,td.hide-lg {
    display: none !important;
  };
}