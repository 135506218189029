.sidenav {
  height: 100%;
  box-sizing: border-box;
  //background-color: #f8fafb;
  //border-right: 1px solid #e8ebe9;
  position: relative;
}

.layout-box.o-scrolls {
  overflow: auto;
}

.layout-box.o-has-rows {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.sidenav-filter-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}
//.filter-bar__filter-wrapper {
//  overflow-x: hidden;
//  overflow-y: auto;
//  box-sizing: border-box;
//  padding: 5px 20px 20px;
//}

//.sidenav {
//  -webkit-user-select: none;
//  -moz-user-select: none;
//  -ms-user-select: none;
//  user-select: none;
//}
//
//.sidenav:before,.sidenav:after {
//  content: " ";
//  display: table;
//}
//
//.sidenav:after {
//  clear: both;
//}
//
//.sidenav-text,.sidenav-item {
//  display: block;
//  padding: 6px 0;
//  font-size: 16px;
//  color: #82888a;
//}
//
//.sidenav-item.icon,.sidenav-item .icon:first-child {
//  font-size: 1.25em;
//}
//
//.sidenav-item.icon {
//  padding-top: 8px;
//}
//
//.sidenav-item .icon:first-child {
//  margin-right: 6px;
//  position: relative;
//  top: 2px;
//}
//
//.sidenav-item:hover,.sidenav-item:focus {
//  text-decoration: none;
//  color: #cacccd;
//}
//
//.sidenav-item[aria-selected="true"] {
//  text-decoration: none;
//  color: #565a5c;
//  font-weight: bold;
//}
//
//@media (max-width: 767px) {
//  .sidenav-text,.sidenav-item {
//    font-size: 15px;
//  };
//}//