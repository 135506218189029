body {
  color: $base-color;
  background-color: #f4f7f8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  &:lang(ja) {
    font-size: 11.5px;
    font-family: "Hiragino Kaku Gothic Pro","ヒラギノ角ゴ Pro W3","メイリオ",Meiryo,"ＭＳ Ｐゴシック",DNRR !important;
  }
}

p {
  margin-top: 0;
  margin-bottom: 15px;
}

a {
  cursor: pointer;
  color: $link-color;
  text-decoration: none;
  -webkit-transition-property: color;
  -moz-transition-property: color;
  -ms-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  transition-duration: 0.3s;
  &:hover {
    text-decoration: none;
    color: $link-hover-color;
  }
}
a.secondary {
  color: #b9b9af;
  text-decoration: underline;
  &:hover, &:focus, &:active {
    color: #515252;
    transition: color 0.3s;
  }
}

.link-blue {
  color: #259af4;
  transition: color 0.3s;
  &:hover, &:focus, &:active {
    color: #0964aa;
    transition: color 0.3s;
  }
}

textarea {
  max-width: 100%;
}

.link-reset, .link-icon {
  color: inherit;
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
  margin-top: 0;
}

h5, .h5 {
  font-size: 16px;
  font-size: 1.231em;
}

.fa-h5 {
  font-size: 16px;
  font-size: 1.231em;
}
h4, .h4 {
  font-size: 20px;
  font-size: 1.538em;
}

h3, .h3 {
  font-size: 24px;
  font-size: 1.846em;
  font-weight: normal;
}

h2, .h2 {
  font-size: 28px;
  font-size: 2.154em;
  font-weight: bold;
}

h1, .h1 {
  font-size: 38px;
  font-size: 2.923em;
  font-weight: normal;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small {
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  color: #82888a;
}
small {
  font-size: 0.85em
}

.font-thin {
  font-weight: 300;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.simple-layer {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  z-index: 9998;
}

.col-center {
  float: none;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 767px) {
  .col-center-sm {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 767px) {
  .sm-text-left {
    text-align: left;
  }
  .select.sm-select-block {
    position: relative;
    width: 100%;
    select {
      width: 100%;
      padding-right: 0;
    }
  }
}

.container-fluid {
  padding-left: 10px;
  padding-right: 10px;
  width: auto;
}

@media (max-width: 767px) {
  .sm-center {
    text-align: center;
  }
  .sm-half-width {
    max-width: 50%;
  }
  .sm-col-center {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}

.img-round, .media-round {
  border-radius: 50%;
  border: 2px solid #fff;
}


.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.radio, .checkbox {
  min-height: 20px;
  padding-left: 20px;
}

input[type="radio"], input[type="checkbox"] {
  position: relative;
  height: 1.25em;
  width: 1.25em;
  margin-bottom: -0.25em;
  margin-right: 5px;
  vertical-align: top;
}

label {
  font-weight: normal;
}

textarea:focus, input:focus, input[type]:focus, .uneditable-input:focus {   
  border-color: #b9b9af;
  box-shadow: none;
  outline: 0 none;
}

.text-muted {
  color: #82888a;
}

.medium-right-margin {
  margin-right: 20px;
}

.medium-left-margin {
  margin-left: 20px;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

ul ul:last-child, ul ol:last-child, ol ul:last-child, ol ol:last-child {
  margin-bottom: 0;
}

.list-unstyled, .list-layout, .subnav-list, .sidenav-list {
  padding-left: 0;
  list-style: none;
}

.media-photo-badge {
  display: inline-block;
  position: relative;
}

.media-photo-block {
  display: block;
}

.media-photo {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  overflow: hidden;
  background-color: #cacccd;
}

.img-round, .media-round {
  border-radius: 50%;
  border: 2px solid #fff;
}


.list-layout {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.form-title {
  border-bottom: 1px solid #b9b9af;
  padding: 10px 0;
}

.form-body {
  padding-top: 30px;
}

.row-condensed {
  margin-left: -6.25px;
  margin-right: -6.25px;
}

.page-container-full {
  width: auto;
}

.page-container-full:before, .page-container-full:after {
  content: " ";
  display: table;
}

.va-container {
  display: table;
  position: relative;
}

.va-container-v {
  width: 100%;
}

.va-container-h {
  height: 100%;
}

.va-middle {
  vertical-align: middle;
  display: table-cell;
}

.row-space-top-large {
  margin-top: 70px;
}

.row-space-large {
  margin-bottom: 70px;
}

@media(max-width: 767px) {
  .row-space-top-large {
    margin-top: 60px;
  }
  
  .row-space-large {
    margin-bottom: 60px;
  }
}

.strong {
  font-weight: bold;
}

.screen-reader-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.col-middle {
  vertical-align: middle;
}

.col-top, .col-middle, .col-bottom {
  float: none;
  display: table-cell;
}

.row.row-table {
  width: 100%;
  width: calc(100% + 20px);
}

.row-table {
  display: table;
  table-layout: fixed;
}

ul.panel-body, ol.panel-body {
  padding: 0;
  list-style: none;
}

ul.panel-body>li, ol.panel-body>li {
  position: relative;
}

.font-green {
  color: #57bb89;
}

.font-purple {
  color: #8a74b9;
}

.font-blue {
  color: #3499e0;
}

.font-orange {
  color: #efb57c;
}

.font-yellow {
  color: #f9d04b;
}

.font-beach {
  color: #ffb400;
}

.font-gray {
  color: #99a2a2;
}

.font-light-gray {
  color: #82888a;
}

dl {
  dt {
    font-size: 18px;
    font-size: 1.385em;
    font-weight: 600;
    margin-bottom: .98em;
  }
  dd {
    font-size: 15px;
    font-size: 1.154em;
    line-height: 26px;
  }
}

.no-margin-top {
  margin-top: 0px;
}

.no-margin-bottom {
  margin-bottom: 0px;
}

.admin-container {
  margin: 0 10px;
}


.task-table-wrapper {
  background: #fff;
  float: left;
}

.hidden {
  display: none!important;
  visibility: hidden;
}

