.materials-panel, .tutor-profile-panel {
  .panel-header {
    background-color: #fff;
    padding: 0;
    font-size: 1.1em;
  }
  .tab-item {
    //border-right: 1px solid #e8ebe9;
  }

  .material-row {
    padding: 1em 0;
    border-bottom: 1px solid #e8ebe9;
  }
}