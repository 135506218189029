.loading:before {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  z-index: 10;
  content: " ";
  background-image: url("#{$images-path}/loading.gif");
  height: 33px;
  width: 33px;
  margin-top: -15px;
  margin-left: -15px;
}

.loading:after {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  content: " ";
  z-index: 9;
  opacity: .9;
  filter: alpha(opacity=90);
}

.loading {
  position: relative;
}

.loading-message {
  position: absolute;
  display: block;
  top: 12em;
  z-index: 11;
  color: inherit;
  font-size: 1.2em;
  position: center;
  text-align: center;
  width: 100%;
}

#app-wrapper.loading:before {
  top: 9em;
}
