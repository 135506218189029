.modal-container, .modal {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  -webkit-transform: translate3d(0, 0, 0);
  background-color: rgba(0,0,0,0.75);
}

.modal.secondary-modal {
  background-color: transparent;
}

//.modal {
//  opacity: 0;
//  -webkit-transition: opacity 0.2s;
//  transition: opacity 0.2s;
//}
//
//.modal.show {
//  opacity: 1;
//}

.modal-table {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
}

.modal-cell {
  display: table-cell;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  padding: 50px;
}

.modal-content {
  position: relative;
  overflow: hidden;
  border-radius: 2px;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 520px;
  z-index: 9999;
}

.secondary-modal .modal-content {
  width: 315px;
  height: 245px;
  border-radius: 2px;
}

//.modal[aria-hidden="false"] {
//  opacity: 1;
//  filter: alpha(opacity=100);
//}

.modal-anim-enter {
  opacity: 0.01;
}

.modal-anim-enter.modal-anim-enter-active {
  opacity: 1;
  transition: opacity 0.2s;
}

.modal-anim-leave {
  opacity: 1;
}

.modal-anim-leave.modal-anim-leave-active {
  opacity: 0.01;
  transition: opacity 0.2s;
}

.modal-content.login {
  width: 350px;
}

@media (max-width: 767px) {
  .modal-content {
    max-width: none;
    height: 100%;
    border-radius: 0;
    overflow: auto;
  }

  .modal.secondary-modal {
    background-color: rgba(0,0,0,0.75);
    .modal-content{
      width: 315px;
      height: 245px;
      border-radius: 2px;
      overflow: hidden;
    }
    .modal-table {
      display: table;
    }

    .modal-cell {
      vertical-align: middle;
      display: table-cell;
    }
  }

  .modal-cell {
    padding: 0;
  }

  .modal-table, .modal-cell {
    display: block;
  }

  .modal-content.login {
    width: 100%;
  }

  .modal-header {
    text-align: center;
  }
}

.modal-close {
  padding: 8px;
  margin-right: -8px;
  margin-top: -8px;
}

.panel-close, .alert-close, .modal-close {
  float: right;
  cursor: pointer;
  font-size: 1.4em;
  line-height: 1;
  vertical-align: middle;
  font-style: normal;
  font-weight: normal;
  color: #cacccd;
}

