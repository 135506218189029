.signup-or-separator {
  border-top: 1px solid #e7e9ec;
  height: 1px;
  margin: 18.75px 0;
  .signup-or-separator--text {
    background: #fff;
    color: #afb9bf;
    display: block;
    margin: -11px auto;
    text-align: center;
    width: 30px;
  }
  h6 {
    font-size: 15px;
  }
}

.link-to-signup-in-login {
  
}

.remember-me {
  float: left;
  padding-top: 0;
  padding-bottom: 0;
}

a.facebook_sign_up_in {
  color: #fff;
}