.icon-green {
  color: #64d481;
}

.icon-purple {
  color: #a695ed;
}

.icon-red {
  color: #ee4d4e;
}

.icon-yellow {
  color: #f9d04b;
}

.circle-green {
  background-color: #64d481;
}

.circle-purple {
  background-color: #a695ed;
}

.circle-red {
  background-color: #ee4d4e;
}

.circle-yellow {
  background-color: #f9d04b;
}