.tutor-panel-buttons {
  .btn {
    width: 45%;
  }
}

@media (max-width: 767px) {
  .tutor-panel.panel {
    border: none;
    background-color: #f4f7f8;
    box-shadow: none;
    border-bottom: 1px solid #dce0e0;
  }
}

.tutor-name {
  font-size: 1.8em;
  font-weight: 300;
}

.rating .star {
  color: #f1c40f;
}

.tutor-image .avatar {
  width: 100px;
  height: 100px;
}

.tutor-filter-nav {
  width: 100%;
  position: relative;
}

.tutor-filter-drawer {
  .dropdown-trigger .dropdown-menu {
    display: block;
    top: 58px;
    right: 0;
    left: auto;
    z-index: 9999;
  }
  ul.dropdown-menu {
    li {
      -webkit-transition-property: background-color,border-color;
      -moz-transition-property: background-color,border-color;
      -ms-transition-property: background-color,border-color;
      transition-property: background-color,border-color;
      -webkit-transition-duration: 0.2s;
      -moz-transition-duration: 0.2s;
      -ms-transition-duration: 0.2s;
      transition-duration: 0.2s;
      display: block;
      position: relative;
      min-height: 45px;
      line-height: 45px;
      cursor: pointer;
      padding: 0px 20px;
      margin: 0px;
    }
    li:hover {
      background-color: #f7f7f7;
    }
    li:after {
      display: block;
      position: absolute;
      left: 20px;
      right: 20px;
      bottom: -1px;
      height: 1px;
      background-color: #f0f0f0;
      content: " ";
      -webkit-transition-property: background-color;
      -moz-transition-property: background-color;
      -ms-transition-property: background-color;
      transition-property: background-color;
      -webkit-transition-duration: 0.2s;
      -moz-transition-duration: 0.2s;
      -ms-transition-duration: 0.2s;
      transition-duration: 0.2s;
    }
  }
  .tutor-filter-nav-left {
    padding-right: 0;
    &:before {
      display: block;
      position: absolute;
      top: 10px;
      bottom: 10px;
      width: 1px;
      background-color: #f0f0f0;
      content: " ";
    }
    &:after {
      display: block;
      position: absolute;
      top: 10px;
      bottom: 10px;
      width: 1px;
      background-color: #f0f0f0;
      content: " ";
      right: 0px;
    }
  }
  .tutor-filter-nav-center {
    padding-left: 0;
    padding-right: 0;
    &:after {
      display: block;
      position: absolute;
      top: 10px;
      bottom: 10px;
      width: 1px;
      background-color: #f0f0f0;
      content: " ";
      right: 0px;
    }
  }
  .tutor-filter-nav-right {
    padding-left: 0;
    padding-right: 0;
    &:after {
      display: block;
      position: absolute;
      top: 10px;
      bottom: 10px;
      width: 1px;
      background-color: #f0f0f0;
      content: " ";
      right: 0px;
    }
  }
  height: 60px;
  border-bottom: 1px solid #e8ebe9;
  background-color: #fff;
  .date-picker-wrap {
    height: 100%;
    border: none;
    position: relative;
    &:hover {
      background-color: #f7f7f7;
      cursor: pointer;
      border: transparent;
      height: 59px;
    }
    .date-picker {
      line-height: 60px;
      text-align: left;
      padding-left: 30px;
      padding-right: 30px;
      width: 100%;
      font-size: 1.077em;
      color: #f15a22;
    }
  }
  .time-picker-wrap {
    height: 100%;
    border: none;
    position: relative;
    &:hover {
      background-color: #f7f7f7;
      cursor: pointer;
      border: transparent;
      height: 59px;
    }
    .time-picker {
      line-height: 60px;
      text-align: left;
      padding-left: 30px;
      padding-right: 30px;
      width: 100%;
      font-size: 1.077em;
      color: #f15a22;
    }
  }
  .duration-picker-wrap {
    height: 100%;
    border: none;
    position: relative;
    &:hover {
      background-color: #f7f7f7;
      cursor: pointer;
      border: transparent;
      height: 59px;
    }
    .duration-picker {
      line-height: 60px;
      text-align: left;
      padding-left: 30px;
      padding-right: 30px;
      width: 100%;
      font-size: 1.077em;
      color: #f15a22;
    }
  }
  .date-picker:after, .time-picker:after, .duration-picker:after {
    position: absolute;
    pointer-events: none;
    color: #f15a22;
    top: 13px;
    bottom: 1px;
    /* padding-top: 0.7em; */
    line-height: 1;
    right: 10px;
    width: 30px;
    text-align: center;
    transform: scale(0.84, 0.42);
    -webkit-transform: scale(0.84, 0.42);
    -moz-transform: scale(0.84, 0.42);
    -ms-transform: scale(0.84, 0.42);
    -o-transform: scale(0.84, 0.42);
    -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.84, M12=0, M21=0, M22=0.42, SizingMethod='auto expand')";
    filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.84, M12=0, M21=0, M22=0.42, SizingMethod='auto expand');
    font-family: FontAwesome;
    content: "\f107";
    font-size: 1.6em;
  }
}

.panel-btn-sm.fixed {
  position: fixed;
  bottom: 40px;
}

.panel-btn-sm {
  padding: 10px 20px;
  left: 0;
  right: 0;
  z-index: 100;
}

.tutor-cards-header {
  .panel-body {
    padding: 13px 12.5px;
  }
  .num-of-tutors {
    line-height: 36px;
    font-weight: 500;
    font-size: 13px;
    color: #82888a;
  }
  .select {
    display: block;
    width: 100%;
    select {
      width: 100%;
    }
  }
}

.tutor-filter-sm {
  .select {
    display: block;
    width: 100%;
    select {
      width: 100%;
    }
  }
}


