.alert {
  padding: 15px;
  padding-right: 30px;
  text-align: center;
  position: relative;
  border-radius: 2px;
  margin-bottom: 0;
}

.panel-header.alert, .alert {
  background-color: #c2e4e7;
}

.panel-header.alert-error, .alert-error {
  background-color: #ffd1c4;
}

.alert-header.panel-header {
  font-size: 1.1em;
}

.alert .alert-close {
  color: #007a87;
}

.alert .alert-close {
  position: absolute;
  right: 15px;
  text-decoration: none;
}

.flash-container.fixed {
  position: fixed;
  z-index: 100000;
  width: 100%;
  height: auto;
  top: 2em;
}

.flash-anim-enter {
  opacity: 0.1;
}

.flash-anim-enter.flash-anim-enter-active {
  opacity: 1;
  transition: opacity 0.1s;
}

.flash-anim-leave {
  opacity: 1;
}

.flash-anim-leave.flash-anim-leave-active {
  opacity: 0.1;
  transition: opacity 0.1s;
}