.calendar-content {
  position: relative;
  overflow: hidden;
  border: none;
  margin-left: auto;
  margin-right: auto;
  border: none;
}

.modal-content, .calendar-content {
  .cal.week-scroll {
    .cal-week-timed {
      // overflow-y: scroll;
      // height: 375px!important;
    }
  }
  .cal {
    width: 100%;
    // overflow-y: scroll;
    // min-height: 200px;
    // max-height: 450px;
    .cal-week-timed {
      // height: 1010px;
      width: 100%;
      border-top: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
      // overflow-x: hidden;
      position: relative;
    }
  }
  .cal-container {
    .calendar-navigation__prev, .calendar-navigation__next {
      cursor: pointer;
      line-height: 0.78;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      //border: 1px solid #dce0e0;
      background-color: #fff;
      color: #757575;
      //border-radius: 3px;
      //padding: 6px 9px;
      top: 18px;
      z-index: 2;
      position: absolute;
    }
    .calendar-navigation__prev {
      left: 22px;
    }
    .calendar-navigation__next {
      right: 22px;
    }
    .cal-date-display {
      color: #3c3f40;
      font-size: 18px;
      text-align: center;
    }
    .cal-header {
      tr {
        height: 20px;
      }
    }
    table {
      table-layout: fixed;
      width: 100%;
      font-size: 0.9em;
      .side {
        text-align: right;
        width: 80px;
        font-size: 1em;
        background-color: #fff;
        padding: 0 8px 0 0;
      }
      .day-row {
        height: 100%;
      }
      tr {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        height: 25px;
      }
      .day-col {
        position: relative;
        height: 100%;
        .events {
          width: 100%;
          position: relative;
          height: 100%;
        }
      }
    }
    table.timeslot-table {
      // position: absolute !important;
      // top: 0px;
      // left: 0px;
      .side {
        border-right: 1px solid #d9d9d9;
        color: #8e8e93;
      }
      .upper {
        border-bottom: 1px dotted rgba(217,217,217,0.7);
      }
      .lower {
        border-bottom: 1px solid rgba(217,217,217,0.7);
      }
      .timeslot{
        border-right: 1px solid #d9d9d9;
        width: 14.2857%;
        height: 25px;
        float: left;
        &.red {
          background-color: #ee4d4e;
        }
        &.light-red {
          background-color: #fd7f7f;
        }
        &.yellow {
          background-color: $light-yellow;
        }
        &.blue {
          background-color: $light-blue;
        }
        &.green {
          background-color: $green;
        }
        &.light-green {
          background-color: #73daa6
        }
      }
      .on {
        background-color: rgba(200, 225, 255, 0.7);
        cursor: pointer;
      }

      .hovered {
        border: 2px solid #2789e4;
        color: #2789e4;
        cursor: pointer;
        line-height: 21px;
      }
      .hover-up {
        border: 2px solid #2789e4;
        cursor: pointer;
        border-top: none;
      }

      .hover-down {
        border: 2px solid #2789e4;
        cursor: pointer;
        border-bottom: none;
      }

      .booked {
        background-color: #e7e7e7;
        cursor: pointer;
      }
      .cancel {
        background-color: #e7e7e7;
        cursor: pointer;
      }
      .text-hovered-double {
        position: relative;
        height: 50px;
        top:15px;
      }
    }
  }
}