@mixin make-space($i: 1) {
  @for $i from 1 through 8 {
    .row-space-#{$i} {
      margin-bottom: (6.25px * $i);
    }
    .row-space-top-#{$i} {
      margin-top: (6.25px * $i);
    }
    @media (max-width: $screen-md-min - 1px) {
      .row-space-top-sm-#{$i} {
        margin-top: (6.25px * $i);
      }
      .row-space-sm-#{$i} {
        margin-bottom: (6.25px * $i);
      }
    }
  }
}