.btn:focus, .btn:active:focus, .btn.active:focus {
  outline: 0 none;
}

.btn {
  font-size: 1em;
  border-radius: $border-radius-base;
  transition: 0.3s;
}

.icon-btn.btn-large {
  position: relative;
  .icon {
    position: absolute;
    font-size: 21px;
    font-size: 1.615em;
    top: 9px;
    left: 20px;
  }
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-small {
  padding: 5.25px 12.5px;
  font-size: 13px;
  font-size: 1em;
  &.with-left-margin {
    margin-left: 0.5em;
  }
}

.btn-large {
  padding: 10.5px 25px;
  font-size: 1.2em;
  .icon.icon-facebook {
    margin-right: 13px;
  }
}

.btn-x-large {
  padding: 14px 32px;
  font-size: 16px;
  font-size: 1.3em;
}

.btn-secondary {
  background-color: #fff;
  color: #565a5c;
  border: $border-base;
  &:hover, &:focus, &:active {
    //background-color: $btn-primary-bg;
    //color: $btn-primary-color;
    border-color: #aaa;
    color: #565a5c;
  }
}

.btn-facebook {
  border-color: #3B5998;
  background-color: #3B5998;
  color: #fff;
  &:hover, &:focus, &:active {
    border-color:#4568b2;
    background-color:#4568b2;
    text-decoration: none;
    color:#fff;
  }
}

.btn-twitter {
  border-color: #55acee;
  background-color: #55acee;
  color: #fff;
  &:hover, &:focus, &:active {
    border-color:#3ea1ec;
    background-color:#3ea1ec;
    text-decoration: none;
    color:#fff;
  }

}

.btn-primary {
  border-color: $btn-primary-border;
  background-color: $btn-primary-bg;
  color: $btn-primary-color;
  &:hover, &:focus {
    background-color: #f15a22;
    border-color: #f15a22;
  }
}

.btn-info {
  border-color: $btn-info-border;
  background-color: $btn-info-bg;
  color: $btn-info-color;
  &:hover, &:focus {
    background-color: #398fd1;
    border-color: #398fd1;
  }
}

.btn-success, .btn-green {
  border-color: $btn-success-border;
  background-color: $btn-success-bg;
  color: #fff;
  &:hover, &:focus {
    background-color: #108d4c;
    border-color: #0f8447;
    color: #fff !important;
  }
}

.btn-light-gray {
  border-color: #f7f7f7;
  background-color: #f7f7f7;
  color: #79776b;
  &:hover, &:focus {
    background-color: #f1f1f1;
    border-color: #f1f1f1;
    color: #79776b;
  }
}

.btn-dark-gray {
  border-color: #99a2a2;
  background-color: #99a2a2;
  color: #fff;
  &:hover, &:focus {
    background-color: #f1f1f1;
    border-color: #f1f1f1;
    color: #565a5c;
  }
}

.btn-blue {
  border-color: #3499e0;
  background-color: #3499e0;
  color: #fff;
  &:hover, &:focus {
    border-color: #1e7fc3;
    background-color: #1e7fc3;
    color: #fff;
  }
}

.btn-purple {
  border-color: #8a74b9;
  background-color: #8a74b9;
  color: #fff;
  &:hover, &:focus {
    border-color: #6F45A5;
    background-color: #6F45A5;
    color: #fff;
  }
}

.btn-orange {
  border-color: #efb57c;
  background-color: #efb57c;
  color: #fff;
  &:hover, &:focus {
    border-color: #e99b4f;
    background-color: #e99b4f;
    color: #fff;
  }
}

.btn-danger, .btn-red {
  border-color: #ee4d4e;
  background-color: #ee4d4e;
  color: #fff;
  &:hover, &:focus {
    border-color: #ec3427;
    background-color: #ec3427;
    color: #fff;
  }
}

.btn-dark-yellow {
  border-color: #f9d04b;
  background-color: #f9d04b;
  color: #fff;
  &:hover, &:focus {
    border-color: #ffa700;
    background-color: #ffa700;
    color: #fff;
  }
}

.btn-light-yellow {
  border-color: #fae86f;
  background-color: #fae86f;
  color: #52657a;
  &:hover, &:focus {
    border-color: #f8e03e;
    background-color: #f8e03e;
    color: #fff; //#52657a
  }
}

.btn-transparent {
  border-color: #fff;
  background-color: transparent;
  color: #fff;
  &:hover, &:focus {
    background-color: #fff;
    color: #565a5c;
  }
}

