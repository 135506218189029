@mixin make-condensed-grid-columns($i: 1, $list: ".row-condensed .col-xs-#{$i}, .row-condensed .col-sm-#{$i}, .row-condensed .col-md-#{$i}, .row-condensed .col-lg-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .row-condensed .col-xs-#{$i}, .row-condensed .col-sm-#{$i}, .row-condensed .col-md-#{$i}, .row-condensed .col-lg-#{$i}";
  }
  #{$list} {
    padding-left: $grid-gutter-width / 4;
    padding-right: $grid-gutter-width / 4;
  }
}

@mixin make-grid-columns($i: 1, $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
  }
  #{$list} {
    padding-left:  $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
  }
}